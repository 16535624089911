import request from '@/util/request'

export function updateAlarmSetting(imei,data) {
    return request({
        url: '/device/setting/alarm/'  + imei,
        method: 'put',
        data:data
    })
}
export function getOrder(imei) {
    return request({
        url: '/device/order/' + imei,
        method: 'get'
    })
}
export function updateOrder(imei,data) {
    return request({
        url: '/device/order/' + imei,
        method: 'put',
        data:data
    })
}
export function getBaseInfo(imei) {
    return request({
        url: '/device/baseInfo/' + imei,
        method: 'get'
    })
}
export function deviceList(param) {
    return request({
        url: '/device/list',
        method: 'post',
        data:param
    })
}
export function unBind(imei) {
    return request({
        url: '/device/unBind',
        method: 'post',
        data: {imei}
    })
}


