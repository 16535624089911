import request from '@/util/request'
export function getSetting(imei) {
    return request({
        url: '/device/setting/' + imei,
        method: 'get'
    })
}
export function updateSetting(imei,data) {
    return request({
        url: '/device/setting/' + imei,
        method: 'put',
        data:data
    })
}
export function listPhoneAlarmRules(imei, alarmType){
    return request({
        url: '/device/setting/phonealarmrules/' + imei,
        method: 'get',
        params:{alarmType}
    })
}
export function addPhoneAlarmRules(imei, rulesId,alarmType){
    return request({
        url: '/device/setting/phonealarmrules/' + imei,
        method: 'post',
        data:{rulesId,alarmType}
    })
}

export function delPhoneAlarmRules(imei, rulesId,alarmType){
    return request({
        url: '/device/setting/phonealarmrules/' + imei,
        method: 'delete',
        data:{rulesId,alarmType}
    })
}

