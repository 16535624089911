<template>

  <div>
    <div v-if="form.input">
      <van-divider class="mydivider" >报警值</van-divider>
      <van-field v-model="form.input.value" :label="form.input.title" :placeholder="form.input.placeholder" :type="form.input.type" />

    </div>

    <van-divider class="mydivider" >通知开关</van-divider>
    <div style="background-color:#ffffff">
      <div>
        <van-cell  :title="form.app.title"  >
          <template #right-icon>
            <van-switch v-model="form.app.checked"  size="24px" @change="onChange" />
          </template>
        </van-cell>
        <div  class="van-cell-labelc">
          {{ form.app.label }}
          <van-divider class="divider"  />
        </div>
      </div>
      <div>
        <van-cell  :title="form.wechat.title"  >
          <template #right-icon>
            <van-switch v-model="form.wechat.checked"  size="24px" @change="onChange" />
          </template>
        </van-cell>
        <div  class="van-cell-labelc">
          {{ form.wechat.label }}
          <van-divider class="divider"  />
        </div>
      </div>
      <div>
        <van-cell  :title="form.sms.title"  >
          <template #right-icon>
            <van-switch v-model="form.sms.checked"  size="24px" @change="onChange" />
          </template>
        </van-cell>
        <div  class="van-cell-labelc">
          {{ form.sms.label }}(即将弃用,请使用电话报警规则中的短信通知)
          <van-divider class="divider"  />
        </div>
      </div>
      <div>
        <van-cell  :title="form.phone.title"  >
          <template #right-icon>
            <van-switch v-model="form.phone.checked"  size="24px" @change="onChange" />
          </template>
        </van-cell>
        <div  class="van-cell-labelc">
          {{ form.phone.label }}
          <van-divider class="divider"  />
        </div>
      </div>

    </div>
    <div v-if="form.phone.checked">
    <van-divider class="mydivider">电话通知规则</van-divider>
    <div style="background-color:#ffffff;padding-bottom: 20px" >
<!--      <van-list-->
<!--          :finished="true"-->
<!--          v-model="loading">-->
        <van-checkbox-group v-model="result">
        <van-cell-group>
          <div :key="item.id" v-for="(item,index) in phoneAlarmRulesList">
            <van-cell
                       :clickable="item.isAdmin"
                       :title="item.name"
                      :label="'创建人：'+item.userNickName"
                      @click="toggle(index)">
              <template #right-icon>
                <van-checkbox  :label-disabled="!item.isAdmin" :disabled="!item.isAdmin" :name="item.id" ref="checkboxes" />
              </template>
            </van-cell>
            <van-divider v-if="index != phoneAlarmRulesList.length - 1" style="margin: 0.1rem 0" />
          </div>
        </van-cell-group>
        </van-checkbox-group>
      <div style="width: 100%;text-align: center">
        <van-button plain type="info" @click="addRules" size="small">添加规则</van-button>
      </div>
<!--      </van-list>-->

      </div>
    </div>
    <div style="padding:0.26667rem 0.42667rem">

      <van-row  gutter="20" style="background-color: #f8f8f8">
        <van-col style="background-color: #f8f8f8" span="7"><van-button plain type="danger" @click="back" round block>返 回</van-button></van-col>
        <van-col style="background-color: #f8f8f8" span="15"><van-button round block type="info" native-type="submit" @click="submit">{{$t("确认修改")}}</van-button></van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import {Col, Row,Cell, CellGroup, Divider, Button, Switch, Field, Notify,List,Checkbox,CheckboxGroup} from 'vant';
import {updateAlarmSetting} from "@/api/device";
import {getImei} from "@/util/session-storage-utils";
import {listPhoneAlarmRules,addPhoneAlarmRules,delPhoneAlarmRules} from "../../../api/devicesetting";
export default {
  components: {
    [Cell.name]:Cell,
    [Col.name]:Cell,
    [Row.name]:Cell,
    [CellGroup.name]:CellGroup,
    [Switch.name]:Switch,
    [Divider.name]:Divider,
    [Field.name]:Field,
    [Notify.name]:Notify,
    [List.name]:List,
    [Checkbox.name]:Checkbox,
    [CheckboxGroup.name]:CheckboxGroup,
    [Button.name]:Button
  },
  name: "index",
  data(){
    return {
      form:this.$route.params,
      isChange:false,
      phoneAlarmRulesList: [
          // {id:1,name:"测试",phoneNumber:"13713511087",userName:"测试用户",isAdmin:true}
          // ,{id:2,name:"测试2",phoneNumber:"13713511088",userName:"测试用户2",isAdmin: false}
          // ,{id:3,name:"测试3",phoneNumber:"13713511089",userName:"测试用户",isAdmin: true}
      ],
      result: [],
      loading:false
    }
  },
  methods:{
    onChange(value){
      this.$emit('change',value)
    },
    submit(){
      this.isChange = true;
      updateAlarmSetting(getImei(),this.form)
          .then(() => {
                Notify({ type: 'success', message: this.$t('数据更新成功'),duration:500 })
                this.$router.go(-1);
          }
              )
    },
    back(){
      window.history.back();
    },
    toggle(index) {
      let checkboxe = this.$refs.checkboxes[index];
      if(checkboxe.disabled){
        return;
      }
      let imei = getImei();
      let phoneAlarmRules = this.phoneAlarmRulesList[index];
      if(checkboxe.checked){
        //取消
        delPhoneAlarmRules(imei,phoneAlarmRules.id,this.form.id).then(()=> {
          Notify({ type: 'success', message: this.$t('数据更新成功'),duration:500 })
        })
      }else{
        //添加
        addPhoneAlarmRules(imei,phoneAlarmRules.id,this.form.id).then(()=> {
          Notify({ type: 'success', message: this.$t('数据更新成功'),duration:500 })
        })
      }
      checkboxe.toggle()

    },
    addRules(){
      this.$router.push({
        name:"phonealarmrules/edit"
      })
    }
  },
  created(){
    if(!this.$route.params.app){
      window.history.back();
    }
    // window.console.log(this.$route.params)
    document.title = this.$t("设备设置");
    listPhoneAlarmRules(getImei(),this.form.id).then(res => {
      let phoneAlarmRulesList = res.data;
      let result = [];
      for (let i = 0; i < phoneAlarmRulesList.length; i++) {
        let phoneAlarmRules = phoneAlarmRulesList[i];
        if(phoneAlarmRules.mappingId != null){
          result.push(phoneAlarmRules.id)
        }
      }
      this.phoneAlarmRulesList = phoneAlarmRulesList;
      this.result = result;
    });
  },
  activated() {
    window.console.log("params",this.$route.params)
  },

  watch:{
  }
  // ,beforeRouteLeave(to, from, next){
  //   // 设置下一个路由的 meta
  //   to.meta.keepAlive = !this.isChange;
  //   next();
  // }
}
</script>
<style scoped src="../../../../src/css/setting/cell.css"/>
<style>
.mydivider{
  margin: 0.2rem 0;
}
</style>
